import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{class:{
        'pt-16 mt-16': _vm.$vuetify.breakpoint.mdAndUp,
        'pt-16': !_vm.$vuetify.breakpoint.mdAndUp && !_vm.$vuetify.breakpoint.xs,
        'pt-6': _vm.$vuetify.breakpoint.xs
    }},[_c(VRow,{staticClass:"justify-center"},[_c(VCol,{class:{ 'px-4': _vm.$vuetify.breakpoint.xs },attrs:{"cols":"auto"}},[_c('p',{staticClass:"font-weight-black",class:{
                    'text-h1': _vm.$vuetify.breakpoint.mdAndUp,
                    'text-h3':
                        !_vm.$vuetify.breakpoint.mdAndUp &&
                        !_vm.$vuetify.breakpoint.xs,
                    'text-h5': _vm.$vuetify.breakpoint.xs
                }},[_vm._v(" Oops! ")]),_c('p',{class:{
                    'text-h5': _vm.$vuetify.breakpoint.mdAndUp,
                    'text-h6':
                        !_vm.$vuetify.breakpoint.mdAndUp &&
                        !_vm.$vuetify.breakpoint.xs,
                    'text-subtitle-1': _vm.$vuetify.breakpoint.xs
                }},[_vm._v(" Our records say that you are not allowed to access the page you are looking for... ")]),_c('p',{staticClass:"text-subtitle-1"},[_vm._v("Error Code: 403")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }