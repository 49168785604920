<template>
    <v-container
        :class="{
            'pt-16 mt-16': $vuetify.breakpoint.mdAndUp,
            'pt-16': !$vuetify.breakpoint.mdAndUp && !$vuetify.breakpoint.xs,
            'pt-6': $vuetify.breakpoint.xs
        }"
    >
        <v-row class="justify-center">
            <v-col cols="auto" :class="{ 'px-4': $vuetify.breakpoint.xs }">
                <p
                    class="font-weight-black"
                    :class="{
                        'text-h1': $vuetify.breakpoint.mdAndUp,
                        'text-h3':
                            !$vuetify.breakpoint.mdAndUp &&
                            !$vuetify.breakpoint.xs,
                        'text-h5': $vuetify.breakpoint.xs
                    }"
                >
                    Oops!
                </p>
                <p
                    :class="{
                        'text-h5': $vuetify.breakpoint.mdAndUp,
                        'text-h6':
                            !$vuetify.breakpoint.mdAndUp &&
                            !$vuetify.breakpoint.xs,
                        'text-subtitle-1': $vuetify.breakpoint.xs
                    }"
                >
                    Our records say that you are not allowed to access the page
                    you are looking for...
                </p>
                <p class="text-subtitle-1">Error Code: 403</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class NotAllowed extends Vue {
    mounted() {
        this.$parent?.$el.classList.add('not-allowed');
    }

    destroyed() {
        this.$parent?.$el.classList.remove('not-allowed');
    }
}
</script>

<style lang="scss">
.v-main.not-allowed,
.v-main.main-background.not-allowed {
    background-color: white !important;
    background-image: url('@/assets/img/403/full.svg');
    background-position: center;
    background-size: contain;
}
</style>
